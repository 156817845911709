import logoLink from '../resources/notrufnoe.svg';

export const cssVariables = {
    active: '#005A9A',
    inactive: '#FFD900',

    primary_light: '#CCDEEB',
    primary: '#005A9A',
    primary_dark: '#00487b',
    text_on_primary: '#fff',
    primary_transparent: 'b3cee1',

    secondary: '#FFD900',
    secondary_light: '#FFF099',
    text_on_secondary: 'white',
    secondary_transparent: 'fff4b3',

    font_color: 'black',
    link_color: '#005A9A',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#005A9A',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#FFD900',

    dropdown_background: '#CCDEEB',

};

export const logo = logoLink;
